import React from "react"
import { Helmet } from "react-helmet";

import './layout.scss'

const Layout = ({ children }) => {
  return (
    <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex" />
          <title>Colin Smith</title>
          <link rel="canonical" href="https://colinsmith.dev" />
        </Helmet>

        {children}
    </>
  )
}

export default Layout
